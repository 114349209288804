import React, { useState } from 'react'

import {EuiButton} from "@elastic/eui"

import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel"

import Metro01 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Advanced_01.webp'
import Metro02 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Advanced_02.webp'
import Metro03 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Advanced_03.webp'
import Metro04 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Advanced_04.webp'
import Metro05 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Advanced_05.webp'
import Metro06 from '../../../../../images/Software/Windows/InstarVision/Metro/InstarVision_Metro_Advanced_06.webp'


const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen }) => {
  return (
    <div>
      <AutoRotatingCarousel
        label="Return"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        mobile
        autoplay={true}
        style={{ position: "absolute" }}
      >
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro01} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Advanced functions"
          subtitle="Enter the Advanced Menu."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro02} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Advanced functions"
          subtitle="Select the camera you want to control."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro03} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Advanced functions"
          subtitle="The functions available in the Advanced Menu are currently worked on will be extended in the future. At the moment there are 3 sub menus..."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro04} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Advanced functions"
          subtitle="User Management: Change your camera admin, user and visitor login."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro05} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Advanced functions"
          subtitle="Alarm Schedule: Change the Alarm Shedule that you set inside your camera&apos;s WebUI."
        />
        <Slide
          media={
            <img alt="InstarVision Windows Metro App" src={Metro06} />
          }
          mediaBackgroundStyle={{ backgroundColor: '#25282f' }}
          style={{ backgroundColor: '#07d' }}
          title="Advanced functions"
          subtitle="System Reboot: Reboot your camera."
        />
      </AutoRotatingCarousel>
    </div>
  );
}

function AdvancedGallery() {
  const [handleOpen, setHandleOpen] = useState({ open: false });
  const handleClick = () => {
    setHandleOpen({ open: true });
  };
  return (
    <>
      <EuiButton fill onClick={handleClick}>
        Image Gallery
      </EuiButton>
      <AutoRotatingCarouselModal
        handleOpen={handleOpen}
        setHandleOpen={setHandleOpen}
      />
    </>
  );
}


export default AdvancedGallery