import React from 'react'

import {
  EuiSpacer,
  EuiTitle
} from '@elastic/eui'

import FloatingGallery from "../../../../../../components/Software/Windows/InstarVision/Metro/instarvision-metro-advanced-floating-gallery"

import NavButtons from '../../../../../../components/Software/Windows/InstarVision/Metro/NavButtons'

import SEOHelmet from '../../../../../../components/Layout/SEOHelmet'
import BreadCrumbs from '../../../../../../components/Layout/BreadCrumbs'


const seodata = {
  title: 'Advanced Menu :: InstarVision for Windows Metro',
  description: 'The Android app InstarVision for your Android tablet or smart phone. Control your camera when you don´t have access to your computer.',
  image: '/images/Search/P_SearchThumb_InstarVision_Metro.png',
  twitter: '/images/Search/P_SearchThumb_InstarVision_Metro.webp',
  locationEN: '/en/Software/Windows/InstarVision/Metro/Advanced/',
  locationDE: '/de/Software/Windows/InstarVision/Metro/Advanced/',
  locationFR: '/fr/Software/Windows/InstarVision/Metro/Advanced/'
}

function InstarVisionAdvanced(props) {
  return (
    <React.Fragment>

      <SEOHelmet
        title={seodata.title}
        description={seodata.description}
        image={seodata.image}
        twitter={seodata.twitter}
        location={props.location} />

      <BreadCrumbs
    locationBC={props.location}
          locationEN={seodata.locationEN}
          locationDE={seodata.locationDE}
          locationFR={seodata.locationFR}
          crumbLabel="Advanced" />
          
      <NavButtons />

      <EuiTitle size="l">
        <h1>InstarVision Surveillance Center</h1>
      </EuiTitle>
      <EuiTitle size="m">
        <h2>Windows Metro Software</h2>
      </EuiTitle>
      <EuiTitle size="s">
        <h4>Advanced Camera Control</h4>
      </EuiTitle>
      <EuiSpacer />
      <FloatingGallery />
    </React.Fragment>
  );
}

export default InstarVisionAdvanced