import React, {Fragment} from 'react'

import {
  EuiBreadcrumbs,
  EuiSpacer
} from '@elastic/eui'


export default function ManualBreadcrumbs() {

  const breadcrumbs = [
    {
      text: 'Advanced',
      href: '/en/Software/Windows/InstarVision/Metro/Advanced/'
    },
    {
      text: 'DDNS',
      href: '/en/Software/Windows/InstarVision/Metro/DDNS/',
    },
    {
      text: 'Multiview',
      href: '/en/Software/Windows/InstarVision/Metro/Multiview/'
    },
    {
      text: 'P2P',
      href: '/en/Software/Windows/InstarVision/Metro/P2P/'
    },
    {
      text: 'Recording',
      href: '/en/Software/Windows/InstarVision/Metro/Recording/'
    },
    {
      text: 'Settings',
      href: '/en/Software/Windows/InstarVision/Metro/Settings/'
    },
    {
      text: '◄ InstarVision Metro',
      href: '/en/Software/Windows/InstarVision/Metro/',
    }
  ];

  return (
    <Fragment>
      <EuiBreadcrumbs
        truncate={true}
        breadcrumbs={breadcrumbs}
        max={11}
        aria-label="Software Platform for your INSTAR IP Camera"
      />
      <EuiSpacer />
    </Fragment>
  );
}